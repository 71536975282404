import { Route, Routes, useLocation } from '@remix-run/react';
import { useEffect } from 'react';

import { PortalLink } from '../../components/PortalContext';
import { useUserRedirect } from '../../hooks/useRedirection';
import { GamePackCenter, Wrapper } from '../GamePack';
import {
  MyGamePackList,
  SearchGamePackList,
  TagGamePackList,
  UntaggedGamePackList,
} from '../GamePack/List';
import { HostView } from './HostView';

// eslint-disable-next-line import/no-default-export
export default function GamePackRoute(): JSX.Element {
  const location = useLocation();
  const redirect = useUserRedirect();

  useEffect(() => {
    redirect([
      { kind: 'admin', target: location.pathname.replace(/^\/host/, '/admin') },
    ]);
  }, [location, redirect]);
  return (
    <HostView
      search={{
        targetPathname: 'search',
        placeholder: 'Search Game Packs',
      }}
      className='bg-game-library bg-w-full bg-no-repeat bg-local'
    >
      <Wrapper
        mode={'host'}
        routePrefix={PortalLink.HostGamePack}
        editRoutePrefix={PortalLink.HostGamePack}
        pageType='default'
        breadcrumbLabel='Game Packs'
      >
        <Routes>
          <Route index element={<GamePackCenter />} />
          <Route path={`my`} element={<MyGamePackList />} />
          <Route path={`tags/:slug`} element={<TagGamePackList />} />
          <Route path={`search`} element={<SearchGamePackList />} />
          <Route path={`untagged`} element={<UntaggedGamePackList />} />
        </Routes>
      </Wrapper>
    </HostView>
  );
}
